/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import type { Theme } from '@emotion/react';

const textBlockStyle = (t: Theme) => css`
  .text h2,
  .text h3,
  .text h4 {
    margin: 1.25em 0 0;
  }

  .text h2:first-of-type,
  .text h3:first-child {
    margin: 0;
  }

  .text h2 {
    font: ${t.headline.h3.font};
  }

  .text h3 {
    font: ${t.headline.h4.font};
  }

  .text a {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  .text a:hover {
    color: ${t.color.primary};
  }

  .text .strong {
    font-weight: bold;
  }

  .text p {
    line-height: 155%;
    margin: 1rem 0;
  }

  .text sup {
    line-height: 0%;
    font-size: 0.75em;
  }

  .text .list {
    margin: 1rem 0;
  }

  .text .list__item,
  .text li {
    display: block;
    position: relative;
    padding: 0.25rem 0 0.25rem 1.5rem;
  }

  .text .list__item::before,
  .text li::before {
    color: ${t.color.primary};
    content: '✓';
    font-family: ${t.font.family.copy};
    font-size: 1em;
    left: 0.125rem;
    position: absolute;
    top: 0.25rem;
  }

  .text .headline {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .text small {
    display: inline-block;
    line-height: 120%;
  }

  .text dt {
    display: inline;
    font-weight: bold;
  }

  .text dd {
    display: inline;
    margin-left: 0.5rem;
  }
`;

export default textBlockStyle;
