const zIndexes = {
  notification: 11000,
  navigation: {
    mobileMenu: 10000
  },
  darkOverlay: 9000,
  productNotification: 8500
};

export { zIndexes };
