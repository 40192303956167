import facepaint from 'facepaint';

export type viewport = 'mobile' | 'tablet' | 'desktop' | 'desktopLarge';
export type breakpoint = {
  [key in viewport]: number;
};

const breakpoints: Record<viewport, number> = {
  mobile: 576,
  tablet: 768,
  desktop: 1024,
  desktopLarge: 1200
} as const;

const mq = facepaint(Object.values(breakpoints).map((bp) => `@media (min-width: ${bp}px)`));

const media = (viewport: viewport) => `@media (min-width: ${breakpoints[viewport]}px)`;

const mediaDown = (viewport: viewport) => `@media (max-width: ${breakpoints[viewport] - 1}px)`;

export { breakpoints, mq, media, mediaDown };
