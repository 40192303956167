import Color from 'color';

import { media } from '@stories/theming/settings';

const fontFaceHeadline = `@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y976aknfjLm_3lMKjiMgmUUYBs04Y8bH-qHHt6M.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}`;

const fontFaceCopy = `@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
};
`;

const fontFaceAccent = `@font-face {
  font-family:"shelby";
  src:url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/de68cc/00000000000000007735b41f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}`;

export const color = {
  primary: 'hsl(73, 60%, 39%)',
  primaryDark: 'hsl(73, 60%, 39%)',
  primaryBackground: 'hsl(73, 60%, 39%)',
  secondary: 'hsl(18, 100%, 12%)',
  copy: 'hsl(0, 0%, 15%)',
  hover: {
    copy: 'hsl(73, 60%, 39%)', //primary
    primary: 'hsl(73, 50%, 59%)',
    g92: 'hsl(0 0% 94%)',
    highlight: ''
  },
  info: 'hsl(208 91% 67%)',
  success: 'hsl(147 71% 31%)',
  black: 'hsl(0 0% 20%)',
  white: 'hsl(0 0% 100%)',
  error: 'hsl(7 71% 48%)',
  highlight: 'hsl(346 57% 43%)',
  accent: '',
  outline: 'hsl(39, 79%, 68%)',
  promotion: 'hsl(0 100% 36%)',
  grey: {
    copy: 'hsl(0 0% 100% / 80%)',
    light: 'hsl(0 0% 96%)',
    dark: 'hsl(0 0% 40%)',
    g20: 'hsl(0, 0%, 20%)',
    g30: 'hsl(0, 0%, 30%)',
    g40: 'hsl(0, 0%, 40%)',
    g60: 'hsl(0, 0%, 60%)',
    g72: 'hsl(0, 0%, 72%)',
    g80: 'hsl(0, 0%, 80%)',
    g85: 'hsl(0, 0%, 85%)',
    g92: 'hsl(0, 0%, 92%)',
    g97: 'hsl(0, 0%, 97%)'
  }
};

export const font = {
  family: {
    headline: "'Yanone Kaffeesatz', sans-serif",
    copy: "'PT Sans', sans-serif",
    accent: "'Shelby', sans-serif"
  },
  size: {
    large: '20px',
    copy: '18px',
    small: '16px',
    footnote: '14px',
    tiny: '12px'
  },
  face: {
    headline: fontFaceHeadline,
    copy: fontFaceCopy,
    accent: fontFaceAccent
  },
  copy: {
    font: 'normal 18px/140% "PT Sans", sans-serif'
  },
  small: {
    font: 'normal 16px/140% "PT Sans", sans-serif'
  },
  footnote: {
    font: 'normal 14px/140% "PT Sans", sans-serif'
  },
  tiny: {
    font: 'normal 12px/140% "PT Sans", sans-serif'
  },
  link: {
    textDecoration: 'underline',
    textDecorationColor: 'hsl(0, 0%, 50%)',
    textUnderlineOffset: '3px',
    ':hover': {
      color: color.primary,
      textDecorationColor: 'currentColor'
    }
  }
};

export const badge = {
  verticalAlign: 0,
  core: {
    alignItems: 'center',
    border: 'thin solid transparent',
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontSize: '11px',
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: '100%',
    padding: '5px .5rem',
    textTransform: 'uppercase'
  },
  new: {},
  bestseller: {},
  discount: {},
  freeShipping: {},
  freeText: {}
};

export const button = {
  primary: {
    solid: {
      backgroundColor: color.primary,
      border: `1px solid ${color.primary}`,
      color: 'white',
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': { backgroundColor: Color(color.primary).darken(0.05).hsl().string(), borderColor: 'white' }
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: `1px solid ${color.primary}`,
      color: color.primary,
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  secondary: {
    solid: {
      backgroundColor: color.secondary,
      border: `1px solid ${color.secondary}`,
      color: 'white',
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: Color(color.secondary).lighten(0.5).hsl().string(),
          borderColor: 'white'
        }
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: `1px solid ${color.secondary}`,
      color: color.secondary,
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  tertiary: {
    solid: {
      backgroundColor: color.grey.g92,
      border: `1px solid ${color.grey.g92}`,
      color: color.primary,
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: Color(color.grey.g92).darken(0.05).hsl().string(),
          borderColor: 'white'
        }
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: 'white',
      textTransform: 'uppercase' as const,
      '@media (hover: hover)': {
        '&:hover': { backdropFilter: 'invert(.08)' }
      }
    }
  },
  small: {
    compensateY: '2px',
    font: 'bold 16px/18px "Yanone Kaffeesatz", sans-serif',
    padding: '6px 1.5rem'
  },
  regular: {
    compensateY: '3px',
    font: 'bold 24px/20px "Yanone Kaffeesatz", sans-serif',
    padding: '.5rem 1.5rem',
    minHeight: '42px'
  },
  big: {
    compensateY: '3px',
    font: 'bold 24px/25px "Yanone Kaffeesatz", sans-serif',
    padding: '.75rem 1.5rem',
    minHeight: '51px'
  }
};

export const header = {
  height: {
    mobile: '140px',
    desktop: '120px'
  },
  burgerColor: color.primary,
  color: color.copy,
  container: {
    backgroundColor: 'white',
    color: color.copy,
    [media('desktop')]: {
      borderBottom: `thin solid ${color.grey.g92}`
    }
  },
  logo: {
    height: '40px'
  },
  navigation: {
    container: {},
    firstLevelItems: {},
    secondLevelItems: {},
    thirdLevelItems: {}
  },
  searchInput: {
    backgroundColor: 'hsl(0 0% 96%)',
    borderRadius: '8px',
    color: color.copy
  },
  topBar: {
    backgroundColor: color.secondary,
    color: 'white',
    font: font.small.font
  },
  cinematic: {
    container: {
      backgroundColor: 'transparent',
      bottom: 0,
      left: 0,
      position: 'absolute' as const,
      transform: 'translateY(100%)',
      width: '100%'
    },
    searchInput: {
      backgroundColor: 'hsl(0 0% 94% / 65%)',
      '::placeholder': {
        color: 'hsl(0deg 0% 0% / 60%)'
      },
      boxShadow: '0 0 12px -4px hsl(0 0% 0% / 22%)'
    }
  }
};
export const footer = {
  backgroundColor: color.secondary,
  color: 'white',
  section: {
    headline: {
      color: color.primary
    }
  }
};

const theme = {
  storeId: 'vomachterhof',
  color,
  border: {
    color: 'hsl(0, 0%, 60%)',
    border: `thin solid ${color.grey.g60}`,
    borderRadius: '8px'
  },
  badge,
  button,
  headline: {
    h1: {
      font: 'bold 45px/110% "Yanone Kaffeesatz", sans-serif',
      letterSpacing: '-0.01em',
      [media('desktop')]: {
        fontSize: '55px'
      }
    },
    h2: {
      font: 'bold 35px/110% "Yanone Kaffeesatz", sans-serif',
      letterSpacing: '0.02em',
      [media('desktop')]: {
        fontSize: '40px'
      }
    },
    h3: {
      font: 'bold 30px/110% "Yanone Kaffeesatz", sans-serif',
      letterSpacing: '0.02em'
    },
    h4: {
      font: 'bold 24px/110% "Yanone Kaffeesatz", sans-serif'
    },
    h5: {
      font: 'bold 20px/120% "PT Sans", sans-serif',
      letterSpacing: '0.02em'
    },
    bumpy: {
      h2: {
        font: 'bold 60px/96% "Yanone Kaffeesatz", sans-serif',
        fontKerning: 'none' as const,
        fontFeatureSettings: '"cpsp" on, "liga" off',
        letterSpacing: '0em',
        [media('desktop')]: {
          fontSize: '70px'
        }
      },
      h4: {
        font: '22px/120% "PT Sans", sans-serif',
        letterSpacing: '0'
      }
    }
  },
  header,
  font,
  footer,
  section: {
    spacing: {
      regular: ['3rem', null, '5rem'],
      short: ['2rem', null, '3rem']
    },
    width: {
      extraWide: '115rem',
      wide: '87.5rem',
      regular: '75rem',
      narrow: '64rem',
      content: '45rem',
      extraNarrow: '35rem'
    }
  }
};

export type DefaultThemeType = typeof theme;

export default theme;
