/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';

type SpinnerProps = {
  size?: string;
};

const bouncedelay = keyframes`
  0%, 80%, 100% {
    WebkitTransform: scale(0);
    transform: scale(0);
  } 40% {
    WebkitTransform: scale(1.0);
    transform: scale(1.0);
  }
`;

const Spinner: React.FC<SpinnerProps> = ({ size = '18px' }) => {
  const pointCss = {
    width: size,
    height: size,
    backgroundColor: 'currentColor',
    marginLeft: '2px',

    borderRadius: '100%',
    display: 'inline-block',
    animation: `${bouncedelay} 1.4s infinite 0.3s ease-in-out both`
  };

  return (
    <div
      css={{
        textAlign: 'center'
      }}
    >
      <div css={{ ...pointCss, animationDelay: '-0.02s' }} />
      <div css={{ ...pointCss, animationDelay: '.16s' }} />
      <div css={pointCss} />
    </div>
  );
};

export default Spinner;
export type { SpinnerProps };
