/** @jsxImportSource @emotion/react */
import { keyframes, useTheme } from '@emotion/react';

import { media } from '@stories/theming/settings';

// TODO put in d.ts file

import Spinner from '../Spinner';

import type DefaultTheme from '@stories/theming/default';

type DefaultThemeType = typeof DefaultTheme;

declare module '@emotion/react' {
  export interface Theme extends DefaultThemeType {}
}

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary';
export type ButtonSize = 'small' | 'regular' | 'big';

type ButtonCustomProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs?: { [key: string]: string | any };
  buttonStyle?: ButtonStyle | null;
  cssProps?: object;
  href?: string | null;
  label2?: string;
  label: string;
  outline?: boolean | null;
  size?: ButtonSize | null;
  isLoading?: boolean;
};

type ButtonProps = ButtonCustomProps extends { href: string }
  ? ButtonCustomProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
  : ButtonCustomProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const appear = keyframes`
0% {opacity: 0}
100% {opacity: 1}
`;

const ButtonSpinner = () => (
  <div
    css={{
      alignItems: 'center',
      animation: `${appear} 200ms 300ms ease-in both`,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      left: 0,
      paddingTop: '4px',
      position: 'absolute',
      right: 0,
      top: 0
    }}
  >
    <Spinner size="12px" />
  </div>
);

const Button: React.FC<ButtonProps> = ({
  attrs = {},
  buttonStyle = 'primary',
  cssProps = {},
  href,
  label = '',
  label2 = '',
  outline = false,
  size = 'regular',
  isLoading = false,
  ...restProps
}) => {
  const theme = useTheme();

  const containerCss = (theme) => {
    // including hover
    const buttonTheme = outline
      ? theme.button[buttonStyle || 'primary'].outline
      : theme.button[buttonStyle || 'primary'].solid;

    return {
      ...buttonTheme,
      cursor: href ? 'pointer' : 'default',
      display: 'block',
      font: theme.button[size || 'regular'].font,
      padding: theme.button[size || 'regular'].padding,
      textAlign: 'center' as const,
      textDecoration: 'none',
      transition: '250ms ease-in-out'
    };
  };

  const defaultLabelDisappearingCss = {
    transition: 'opacity ease-in-out 200ms, transform ease-in-out 200ms',
    ...(isLoading && { opacity: 0, transform: 'scale(0.3)' })
  };

  const children = (
    <>
      {isLoading && <ButtonSpinner />}
      <div
        css={(t) => ({
          transform: `translateY(${t.button[size || 'regular'].compensateY})`
        })}
      >
        <span css={{ ...defaultLabelDisappearingCss, display: 'block' }}>
          {label.split('//').map((s) => (
            <span key={s} css={{ [media('tablet')]: { display: 'block' } }}>
              &nbsp;{s.trim()}
            </span>
          ))}
        </span>
        {label2 && (
          <span
            css={(t) => ({
              display: 'block',
              font: `normal 18px/24px ${t.font.family.copy}`,
              letterSpacing: '0',
              lineHeight: '110%',
              opacity: '.7',
              textTransform: 'none',
              ...defaultLabelDisappearingCss
            })}
          >
            {label2}
          </span>
        )}
        {/* {isSuccess ? (
      <span
        css={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          gap: '.5rem',
          margin: 'auto',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <span css={{ fontSize: '1.3em' }}>✓</span>
        {translations.Added.de_DE}
      </span>
    ) : (
      ''
    )} */}
      </div>
    </>
  );

  const buttonCss = {
    ...containerCss(theme),
    ...cssProps,
    position: 'relative',
    ...(isLoading && {
      pointerEvents: 'none',
      cursor: 'default',
      ...containerCss(theme)[':hover']
    })
  };

  return typeof href === 'string' ? (
    <a
      href={href}
      css={buttonCss}
      {...attrs}
      {...(restProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
    >
      {children}
    </a>
  ) : (
    <button css={buttonCss} {...attrs} {...(restProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {children}
    </button>
  );
};
export default Button;
export { ButtonSpinner };
export type { ButtonProps };
